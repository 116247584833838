<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <div class="relative">
      <BlokkliField
        v-slot="{ items }"
        :list="paragraphsHeader"
        name="field_paragraphs_header"
        non-empty-class="!absolute bottom-0 z-50 left-0 flex w-full justify-center"
        edit-only
      >
        <PageHeaderSlider v-if="items.length" :slides="getSlideItems(items)" />
        <PageHeader
          v-else
          :title="entity?.title || title"
          :lead="entity?.lead || lead"
          blokkli
        >
          <BlokkliField
            :list="paragraphsAside"
            name="field_paragraphs_aside"
            class="w-[140px] md:w-[290px] lg:w-[300px] xl:w-[330px] 2xl:w-[360px] float-right md:order-last ml-16 lg:-my-24"
          />
        </PageHeader>
      </BlokkliField>
    </div>

    <slot />

    <div class="layout-grid">
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="bg-white relative z-20 col-start-1 col-end-[-1]"
        drop-alignment="vertical"
        :allowed-fragments="[
          'agencies',
          'bancomat',
          'events',
          'management',
          'media',
          'mortgage_calculator',
          'news',
          'online_services_overview',
          'people',
          'quicksearch',
        ]"
      />
    </div>
    <BlokkliField
      :list="paragraphContactTeaser"
      name="field_paragraph_contact_teaser"
      class="mt-32"
    />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type { FieldListItemTyped } from '#blokkli/generated-types'
import type {
  NodePageFragment,
  ParagraphSlideFragment,
} from '#graphql-operations'

const props = defineProps<{
  uuid: string
  id?: string
  title?: string
  lead?: string
  blokkliProps: NodePageFragment['blokkliProps']
  paragraphs?: NodePageFragment['paragraphs']
  paragraphsHeader?: NodePageFragment['paragraphsHeader']
  paragraphsAside?: NodePageFragment['paragraphsAside']
  paragraphContactTeaser?: NodePageFragment['paragraphContactTeaser']
  body?: string
  canEdit?: NodePageFragment['canEdit']
}>()

useCurrentNode(props)

function getSlideItems(items: FieldListItemTyped[]) {
  return items
    .map((item) => {
      if (item.bundle === 'slide') {
        return {
          ...item,
          props: item.props as ParagraphSlideFragment,
        }
      }
      return null
    })
    .filter(notNullish)
}
</script>
