<template>
  <div class="pt-header paragraph-slide absolute top-0 left-0 size-full">
    <div
      v-if="hasGradient"
      class="absolute top-0 left-0 w-full h-[290px] z-40 hidden md:block"
      :style="gradientStyle"
    />
    <MediaImage
      v-blokkli-droppable:field_media_image
      v-bind="image"
      :image-style="imageStyle"
      img-class="absolute top-0 left-0 w-full h-full object-cover pointer-events-none"
    />
    <div
      v-if="title || subtitle"
      class="layout-grid relative z-10 items-center h-full md:pb-96"
    >
      <div class="col-start-1 col-end-[-1]">
        <VuepalLink :to="to" class="max-w-3xl header-slider-title">
          <div v-if="title" class="brand-bar is-large">
            <h1 class="brand-bar-inner" v-html="$sup(title)" />
          </div>
          <div v-if="subtitle" class="brand-bar is-gray">
            <p class="brand-bar-inner" v-html="$sup(subtitle)" />
          </div>
        </VuepalLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphSlideFragment } from '#graphql-operations'

const props = defineProps<{
  image?: ParagraphSlideFragment['image']
  link?: ParagraphSlideFragment['link']
  title?: string
  subtitle?: string
  hasGradient?: boolean
  isInverted?: boolean
  gradientColor?: string
}>()

const { $sup } = useNuxtApp()

function hexToRgb(hex: string): number[] {
  const color = hex.replace('#', '')
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)
  return [r, g, b]
}

const gradientColorValue = computed(() => {
  if (props.gradientColor) {
    return props.gradientColor
  } else if (props.isInverted) {
    return '#000000'
  }
  return '#ffffff'
})

const gradientStyle = computed(() => {
  if (!props.hasGradient) {
    return
  }

  const rgb = hexToRgb(gradientColorValue.value).join(',')

  return {
    background: `linear-gradient(180deg, rgba(${rgb}, 0.8), rgba(${rgb}, 0.0))`,
  }
})

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 450,
      height: 880,
    },
    md: {
      width: 880,
      height: 880,
    },
    lg: {
      width: 1024,
      height: 880,
    },
    xl: {
      width: 1920,
      height: 880,
    },
    '2xl': {
      width: 1500,
      height: 880,
    },
    '3xl': {
      width: 1920,
      height: 880,
    },
    '4xl': {
      width: 2560,
      height: 880,
    },
  },
})

const to = computed(() => props.link?.uri?.path)
</script>

<style lang="postcss">
.paragraph-slide {
  .brand-bar-inner {
    @apply pl-16 md:pl-28;
  }
}
</style>
