<template>
  <div
    class="relative overflow-hidden -mt-header"
    :class="{ '-mb-112': slides.length < 2 }"
  >
    <div class="relative h-[420px] lg:h-[880px]">
      <Transition name="header-slider">
        <Slide
          v-if="activeSlide"
          :key="activeSlide.uuid"
          v-bind="activeSlide.props"
          :has-gradient="activeSlide.options?.hasGradient === '1'"
          :is-inverted="activeSlide.options?.inverted === '1'"
          :gradient-color="activeSlide.options?.gradientColor"
        />
      </Transition>
    </div>

    <div
      v-if="slides.length > 1"
      class="absolute bottom-0 left-0 w-full z-30 hero-slider-buttons pt-40 px-16 md:px-0"
    >
      <div class="layout-grid">
        <div
          class="col-start-1 col-end-[-1] flex items-end md:bg-black/50 md:backdrop-blur-2xl gap-8 md:gap-0"
        >
          <button
            v-for="(slide, index) in slides"
            :key="'button_' + slide.uuid"
            class="flex-1 text-left text-white font-bold max-w-[400px] flex gap-16 py-16 md:p-20 opacity-50"
            :class="{
              '!opacity-100': index === activeIndex,
              'md:hover:opacity-70': index !== activeIndex,
            }"
            @click.prevent="activeIndex = index"
          >
            <div
              class="h-8 bg-white w-full md:size-auto md:bg-transparent rounded md:rounded-none"
            >
              <div
                class="text-3xl font-bold w-32 md:w-80 aspect-square hidden md:flex items-center justify-center"
              >
                <MediaImage
                  v-if="slide.props.image"
                  v-bind="slide.props.image"
                  :image-style="imageStyle"
                />
              </div>
            </div>
            <div class="hidden md:block">
              {{ slide.props.title }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Slide from './Slide/index.vue'
import type { FieldListItemTyped } from '#blokkli/generated-types'
import type { ParagraphSlideFragment } from '#graphql-operations'

type ItemWithSlide = FieldListItemTyped & { props: ParagraphSlideFragment }

const props = defineProps<{
  slides: ItemWithSlide[]
}>()

const activeIndex = ref(0)

const activeSlide = computed(() => props.slides[activeIndex.value])

const isInverted = computed(() => activeSlide.value?.options?.inverted === '1')

useHead({
  htmlAttrs: {
    class: {
      'has-header-inverted': isInverted,
    },
  },
})

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 80,
  },
})
</script>

<style lang="postcss">
.header-slider-enter-active,
.header-slider-leave-active {
  @apply transition origin-top duration-500 ease-swing;

  .brand-bar {
    @apply transition origin-top duration-500 ease-swing;
  }
}
.header-slider-enter-from,
.header-slider-leave-to {
  @apply opacity-0;
}

.header-slider-enter-from {
  .brand-bar {
    @apply translate-x-40 md:translate-x-112;
  }
}

.header-slider-leave-to {
  .brand-bar {
    @apply -translate-x-40 md:-translate-x-112;
  }
}
</style>
